import React, { useEffect, useState } from "react";
import {
  listLicenses,
  License,
  deleteLicense,
  LicenseInfo,
  updateLicense,
  createLicense,
} from "../Services/ApiServices";
import {
  Grid,
  makeStyles,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  TablePagination,
  TableSortLabel,
  Backdrop,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Update,
  Delete,
  FileCopyOutlined,
  Add,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  handleErrorGeneric,
  handleTimeandBy,
  expiresInDays,
  formatTimeStamp,
} from "../Services/Util";
import LicenseDialog from "./LicenseDialog";
import CopyToClipboard from "react-copy-to-clipboard";
import theme from "../theme";
import SearchComponent from "../Search/Search";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: {
  row: License;
  searchValue: string;
  onUpdate: (license: License) => void;
  onDelete: (license: License) => void;
}) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const expiresIn = expiresInDays(row.info.expiredDate);
  const { enqueueSnackbar } = useSnackbar();

  let parts =
    props.searchValue.length > 0
      ? row.info.userAccountName.split(
          new RegExp(`(${props.searchValue})`, "gi")
        )
      : [];
  const convertedAccountName =
    parts.length <= 1
      ? row.info.userAccountName
      : parts.map((part, i) => (
          <p
            key={i}
            style={{
              color:
                part.toLowerCase() === props.searchValue.toLowerCase()
                  ? "#222"
                  : "#777",
              fontWeight:
                part.toLowerCase() === props.searchValue.toLowerCase()
                  ? "bold"
                  : undefined,
              display: "inline",
            }}
          >
            {part}
          </p>
        ));

  parts =
    props.searchValue.length > 0 && row.info.serverName
      ? row.info.serverName.split(new RegExp(`(${props.searchValue})`, "gi"))
      : [];
  const convertedServerName =
    parts.length <= 1
      ? row.info.serverName
      : parts.map((part, i) => (
          <p
            key={i}
            style={{
              color:
                part.toLowerCase() === props.searchValue.toLowerCase()
                  ? "#222"
                  : "#777",
              fontWeight:
                part.toLowerCase() === props.searchValue.toLowerCase()
                  ? "bold"
                  : undefined,
              display: "inline",
            }}
          >
            {part}
          </p>
        ));

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {convertedServerName}
        </TableCell>
        <TableCell component="th" scope="row">
          {convertedAccountName}
        </TableCell>
        <TableCell align="right">{row.info.modellerUserCount}</TableCell>
        <TableCell align="right">{row.info.viewerUserCount}</TableCell>
        <TableCell align="right">{row.info.maxNumberOfCores}</TableCell>
        <TableCell align="right">{row.info.deploymentMode}</TableCell>
        <TableCell align="right">{expiresIn}</TableCell>
        <TableCell align="right">
          <Grid container justify="flex-end" wrap="nowrap">
            <Grid item>
              <CopyToClipboard
                text={row.licenseKey}
                onCopy={() => {
                  enqueueSnackbar(
                    row.info.userAccountName +
                      "'s license key copied to the clipboard!",
                    { variant: "info" }
                  );
                }}
              >
                <Tooltip
                  placement="top"
                  title={"Copy Licence Key to Clipboard"}
                  aria-label="Copy License Key to Clipboard"
                >
                  <IconButton>
                    <FileCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              <Tooltip placement="top" title={"Update"} aria-label="Update">
                <IconButton
                  onClick={() => {
                    props.onUpdate(row);
                  }}
                >
                  <Update fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip placement="top" title={"Remove"} aria-label="Remove">
                <IconButton
                  onClick={() => {
                    props.onDelete(row);
                  }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "rgba(0,0,0,0.07)" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Grid container>
                <Grid item style={{ width: "7%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Id
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={String(row.id)}
                    >
                      {row.id}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "11%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Owner Id
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={String(row.ownerId)}
                    >
                      {row.ownerId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "11%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Account ID
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={String(row.info.userAccountID)}
                    >
                      {row.info.userAccountID}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "11%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Unique Key
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={String(row.info.uniqueKey)}
                    >
                      {row.info.uniqueKey}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "15%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Created Date & By
                    </Typography>
                    {row.createdDate
                      ? [
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "calc(100% - 10px)",
                            }}
                            title={formatTimeStamp(row.createdDate)}
                          >
                            {formatTimeStamp(row.createdDate)}
                          </Typography>,
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "calc(100% - 10px)",
                            }}
                            title={row.createdUser}
                          >
                            by {row.createdUser || "-"}
                          </Typography>,
                        ]
                      : "-"}
                  </Grid>
                </Grid>
                <Grid item style={{ width: "15%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Updated Date & By
                    </Typography>
                    {row.lastModifiedDate
                      ? [
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "calc(100% - 10px)",
                            }}
                            title={formatTimeStamp(row.lastModifiedDate)}
                          >
                            {formatTimeStamp(row.lastModifiedDate)}
                          </Typography>,
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "calc(100% - 10px)",
                            }}
                            title={row.lastModifiedUser}
                          >
                            by {row.lastModifiedUser || "-"}
                          </Typography>,
                        ]
                      : "-"}
                  </Grid>
                </Grid>
                <Grid item style={{ width: "15%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Activation Expired Date
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={formatTimeStamp(row.info.activationExpiredDate)}
                    >
                      {formatTimeStamp(row.info.activationExpiredDate)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "15%" }}>
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "0.9rem",
                        color: "#666",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      Expired Date
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 10px)",
                      }}
                      title={formatTimeStamp(row.info.expiredDate)}
                    >
                      {formatTimeStamp(row.info.expiredDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

enum headerIds {
  serverName = "serverName",
  accName = "userAccountName",
  accId = "userAccountID",
  model = "modellerUserCount",
  view = "viewerUserCount",
  maxCores = "maxNumberOfCores",
  actExpired = "activationExpiredDate",
  expired = "expiredDate",
  deployMode = "deploymentMode",
  uKey = "uniqueKey",
}

type Order = "asc" | "desc";

function licenseSort(
  licenses: Array<License>,
  order: Order,
  orderBy: headerIds
): Array<License> {
  return licenses.sort((el1, el2) => {
    if (order === "asc") {
      if (el1.info[orderBy] && el2.info[orderBy]) {
        return el1.info[orderBy]! > el2.info[orderBy]!
          ? 1
          : el2.info[orderBy]! > el1.info[orderBy]!
          ? -1
          : 0;
      } else {
        return el1.info[orderBy] && !el2.info[orderBy]
          ? 1
          : el2.info[orderBy] && !el1.info[orderBy]
          ? -1
          : 0;
      }
    } else {
      if (el1.info[orderBy] && el2.info[orderBy]) {
        return el1.info[orderBy]! > el2.info[orderBy]!
          ? -1
          : el2.info[orderBy]! > el1.info[orderBy]!
          ? 1
          : 0;
      } else {
        return el1.info[orderBy] && !el2.info[orderBy]
          ? -1
          : el2.info[orderBy] && !el1.info[orderBy]
          ? 1
          : 0;
      }
    }
  });
}

function LicenseList() {
  const [licenses, setLicenses] = useState<Array<License>>([]);
  const [filteredLicenses, setFilteredLicenses] = useState<Array<License>>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [addOpen, setAddopen] = useState<boolean>(false);
  const [key, setKey] = useState<License | undefined>(undefined);
  const [operating, setOperating] = useState<boolean>(false);
  const [licForUpdate, setUpdateLic] = useState<License | undefined>(undefined);
  const [licForDelete, setDeleteLic] = useState<License | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<headerIds>(headerIds.expired);
  const [searchValue, setSearchValue] = useState<string>("");
  const [windowHeight, setWindowHeight] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchLicenses();
    // Handler to call on window resize
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchLicenses = async () => {
    setFetching(true);
    try {
      const result = await listLicenses();
      setLicenses(result.licenses);
      setFilteredLicenses(result.licenses);
    } catch (error) {
      enqueueSnackbar(handleErrorGeneric(error), { variant: "error" });
    }
    setFetching(false);
  };

  const handleUpdate = (license: License) => {
    setUpdateLic(license);
  };

  const handleDelete = (license: License) => {
    setDeleteLic(license);
  };

  const handleCloseDelete = () => {
    setDeleteLic(undefined);
  };

  const handleDeleteYes = () => {
    const removeLicense = async () => {
      setOperating(true);
      try {
        if (!licForDelete) {
          throw Error("Some unexpected happened. Please try again!");
        } else {
          await deleteLicense(licForDelete?.id);
        }
      } catch (error) {
        enqueueSnackbar(handleErrorGeneric(error), { variant: "error" });
      }
      setOperating(false);
      handleCloseDelete();
      fetchLicenses();
    };
    removeLicense();
  };

  const handleCancel = () => {
    setUpdateLic(undefined);
    setAddopen(false);
  };

  const handleSubmit = (info: LicenseInfo, id?: number) => {
    const submit = async () => {
      setOperating(true);
      handleCancel();
      try {
        if (id) {
          const result = await updateLicense(id, info);
          setKey(result);
        } else {
          const result = await createLicense(info);
          setKey(result);
        }
      } catch (error) {
        enqueueSnackbar(handleErrorGeneric(error), { variant: "error" });
      }
      setOperating(false);
      fetchLicenses();
    };
    submit();
  };

  const handleCloseKey = () => {
    setKey(undefined);
  };

  const handleCreate = () => {
    setAddopen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleRequestSort = (property: headerIds) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    setFilteredLicenses(
      licenses.filter((license) => {
        return (
          license.info.userAccountName
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .indexOf(
              value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) > -1 ||
          (license.info.serverName !== undefined &&
            license.info.serverName
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .indexOf(
                value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              ) > -1)
        );
      })
    );
  };

  return (
    <Grid style={{ height: "calc(100% - 64px)" }}>
      <Backdrop
        open={operating || fetching}
        style={{ zIndex: 5, backgroundColor: "rgba(0,0,0,0.45)" }}
      >
        <CircularProgress style={{ color: "#ccc" }} />
      </Backdrop>
      <Grid
        container
        justify="flex-end"
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: "64px",
          zIndex: 3,
          boxShadow: "0 0 10px -7px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: "5px 15px", color: "#fff" }}
          onClick={handleCreate}
        >
          <Add /> New License
        </Button>
      </Grid>
      {!fetching && (
        <Paper style={{ width: "100%", height: windowHeight - 110 }}>
          <TableContainer style={{ maxHeight: windowHeight - 160 }}>
            <Table stickyHeader aria-label="license table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <SearchComponent
                      searchString={searchValue}
                      countText={
                        filteredLicenses.length + " of " + licenses.length
                      }
                      onChange={handleSearchChange}
                    />
                  </TableCell>
                  <TableCell
                    key={headerIds.serverName}
                    align={"left"}
                    sortDirection={
                      orderBy === headerIds.serverName ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.serverName}
                      direction={
                        orderBy === headerIds.serverName ? order : "asc"
                      }
                      onClick={() => handleRequestSort(headerIds.serverName)}
                    >
                      Server Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.accName}
                    align={"left"}
                    sortDirection={
                      orderBy === headerIds.accName ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.accName}
                      direction={orderBy === headerIds.accName ? order : "asc"}
                      onClick={() => handleRequestSort(headerIds.accName)}
                    >
                      Account Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.model}
                    align={"right"}
                    sortDirection={orderBy === headerIds.model ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.model}
                      direction={orderBy === headerIds.model ? order : "asc"}
                      onClick={() => handleRequestSort(headerIds.model)}
                    >
                      #Modeller
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.view}
                    align={"right"}
                    sortDirection={orderBy === headerIds.view ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.view}
                      direction={orderBy === headerIds.view ? order : "asc"}
                      onClick={() => handleRequestSort(headerIds.view)}
                    >
                      #Viewer
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.maxCores}
                    align={"right"}
                    sortDirection={
                      orderBy === headerIds.maxCores ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.maxCores}
                      direction={orderBy === headerIds.maxCores ? order : "asc"}
                      onClick={() => handleRequestSort(headerIds.maxCores)}
                    >
                      maxNumOfCores
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.deployMode}
                    align={"right"}
                    sortDirection={
                      orderBy === headerIds.deployMode ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.deployMode}
                      direction={
                        orderBy === headerIds.deployMode ? order : "asc"
                      }
                      onClick={() => handleRequestSort(headerIds.deployMode)}
                    >
                      Deployment Mode
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key={headerIds.expired}
                    align={"right"}
                    sortDirection={
                      orderBy === headerIds.expired ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headerIds.expired}
                      direction={orderBy === headerIds.expired ? order : "asc"}
                      onClick={() => handleRequestSort(headerIds.expired)}
                    >
                      Expires
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenses.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      No License yet
                    </TableCell>
                  </TableRow>
                )}
                {licenseSort(filteredLicenses, order, orderBy)
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((row) => (
                    <Row
                      key={row.licenseKey}
                      row={row}
                      searchValue={searchValue}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredLicenses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ boxShadow: "0 0 6px -3px" }}
          />
        </Paper>
      )}
      {licForDelete && (
        <Dialog open={Boolean(licForDelete)} onClose={handleCloseDelete}>
          <DialogTitle>
            {"Remove License for " + licForDelete.info.userAccountName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This is an irreversible operation. Are you sure to remove{" "}
              {" '" + licForDelete.info.userAccountName + "' "} account license?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteYes}
              style={{ color: theme.palette.error.main }}
              autoFocus
            >
              Yes, remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {key && (
        <Dialog open={Boolean(key)} onClose={handleCloseKey}>
          <DialogTitle>
            {"License Key for " + key.info.userAccountName}
          </DialogTitle>
          <DialogContent>
            <Paper
              style={{
                width: "calc(100%)",
                overflow: "auto",
                boxShadow: "none",
                border: "1px solid #ddd",
              }}
            >
              <Grid container wrap="nowrap" style={{ overflow: "hidden" }}>
                <Grid
                  item
                  style={{
                    width: "calc(100% - 30px)",
                    overflow: "auto",
                    paddingLeft: "10px",
                  }}
                >
                  <pre>{key.licenseKey}</pre>
                </Grid>
                <Grid
                  item
                  style={{ backgroundColor: "#f5f5f5", width: "30px" }}
                >
                  <Grid container justify="center" style={{ height: "100%" }}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      style={{ height: "100%" }}
                    >
                      <CopyToClipboard
                        text={key.licenseKey}
                        onCopy={() => {
                          enqueueSnackbar(
                            key.info.userAccountName +
                              "'s license key copied to the clipboard!",
                            { variant: "info" }
                          );
                          handleCloseKey();
                        }}
                      >
                        <Tooltip
                          placement="top"
                          title={"Copy Key to Clipboard"}
                          aria-label="Copy Key to Clipboard"
                        >
                          <IconButton
                            size="small"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <FileCopyOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseKey} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {(addOpen || licForUpdate) && (
        <LicenseDialog
          open={Boolean(addOpen || licForUpdate)}
          license={licForUpdate}
          onSend={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Grid>
  );
}

export default LicenseList;
