import { AxiosError } from 'axios';

function handleHttpError(err: AxiosError){
    const respData = err.response!.data;
    if (respData.code) {
        return String(respData.code) + ": " + String(respData.message);
    } else {
        return String(err.response!.status) + ": " + String(err.response!.statusText);
    }
}

export function handleErrorGeneric(error: any){
    if (error.response) {
        return handleHttpError(error);
    } else if(error.code && error.message){
        return String(error.code) + ": " + String(error.message);
    } else if (error.request) {
        return "No response from server. Maybe no connection?";
    } else {
        return "Some unexpected error happened!";
    }
}

export function handleTimeandBy(time?: number, by?: string): string {
    if(time){
        return formatTimeStamp(time) + " by " + (by?by:"-");
    } else {
        return "-";
    }
}

export function formatTimeStamp(ts: number): string{
    const d = new Date(ts);
    const tzo = -d.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = function(num: number) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };

    return d.getFullYear() +
            '-' + pad(d.getMonth() + 1) +
            '-' + pad(d.getDate()) +
            ' ' + pad(d.getHours()) +
            ':' + pad(d.getMinutes()) +
            ':' + pad(d.getSeconds()) +
            ' GMT' + dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
}

export function expiresInDays(expiredDate: number): string {
    const expiredAt = new Date(expiredDate);
    const expiresIn = Math.floor((expiredAt.getTime() - (Date.now())) / (1000 * 3600 * 24));
    return expiresIn<0?"Expired":expiresIn===0?"Today":"In "+String(expiresIn)+" (days)";
}

export function convertTStoDateTimeValue(ts: number): string{
    const str = new Date(ts).toISOString();
    return str.split(".")[0];
}