import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
    	primary: {
    		main: "#343233",
    	},
    	secondary: {
    		main: "#0BA9A9"
    	},
     	error: {
     		main: "#E7411B"
		 },		 
    }
});

export const reverseTheme = createMuiTheme({
    palette: {
    	primary: {
    		main: "#0BA9A9"
    	},
    	secondary: {
    		main: "#343233",
    	},
     	error: {
     		main: "#E7411B"
		 },		 
    }
});

export default theme;
