import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './Services/serviceWorker';

import Keycloak from 'keycloak-js';

export function logoutKeycloak () {
    window.sessionStorage.clear();
    keycloak.clearToken();
    keycloak.logout().success(() => {
        console.log("keycloak logout success.")
    }).error(() => {
        console.log("keycloak logout failed.");
        window.location.reload();
    })
}

function refreshKeycloakToken(){
    if(keycloak.isTokenExpired(10 + (keycloak.timeSkew?(keycloak.timeSkew):0))){
        keycloak.updateToken(-1)
            .success(refreshed=>{
                if (refreshed) {
                    if(keycloak.isTokenExpired(10 + (keycloak.timeSkew?(keycloak.timeSkew):0))){
                        logoutKeycloak();
                    } else{
                        window.sessionStorage.setItem("loginToken", keycloak.token || "");
                    }
                } else {
                    console.log('Token is still valid');
                }
            }).error(err=> {
                console.log('Failed to refresh the token, or the session has expired');
                logoutKeycloak();
            })
    }
}

export const keycloak = new Keycloak('/keycloak.json');
keycloak.init({onLoad: 'login-required', 'checkLoginIframe' : false}).success(() => {
    window.sessionStorage.setItem("loginToken", keycloak.token || "");
    // keycloak.onTokenExpired = refreshKeycloakToken;
    setInterval(refreshKeycloakToken , 10000);
    ReactDOM.render(
      <React.StrictMode>
        <App/>
      </React.StrictMode>,
      document.getElementById('root')
    );
}).error(err => {
    console.error(err);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
