import React, { useState } from 'react';
import { makeStyles, IconButton, InputBaseProps, InputBase, fade, Typography } from '@material-ui/core';
import theme from '../theme';
import { Search, Close } from '@material-ui/icons';

const useStylesInputBase = makeStyles({
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: "70px",
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '200px',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: "rgba(0,0,0,0.05)",
            borderBottom: '1px solid rgba(0,0,0,0.2)',
            '&:hover': {
                backgroundColor: "rgba(0,0,0,0.1)",
                borderBottom: '1px solid rgba(0,0,0,0.5)',
            },
            '&:focus': {
                backgroundColor: fade(theme.palette.secondary.main, 0.075),
                borderBottom: "1px solid fade(theme.palette.secondary.main, 0.5)",
            },
        },
    }
});

export function StyledInputBase(props: InputBaseProps) {
    const classes = useStylesInputBase();
    return (
        <InputBase {...props} classes={{ root: classes.inputRoot, input: classes.inputInput }} />
    );
}

const useStylesSearch = makeStyles({
    search: {
        position: 'relative',
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default function SearchComponent(props: { searchString: string; countText: string; onChange: (searchString: string) => void; }) {
    const classes = useStylesSearch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    }

    const handleClear = () => {
        props.onChange("");
    }

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <Search />
            </div>
            <StyledInputBase
                id={"search-area"}
                placeholder="Search"
                onChange={handleChange}
                value={props.searchString}
            />
            <div className={classes.closeIcon} style={{ display: props.searchString && props.searchString.length > 0 ? "flex" : "none", right: "25px" }}>
                <Typography variant="caption" >{props.countText}</Typography>
            </div>
            <div className={classes.closeIcon} style={{ display: props.searchString && props.searchString.length > 0 ? "flex" : "none" }}>
                <IconButton style={{ padding: '5px' }} onClick={handleClear}>
                    <Close fontSize="small" />
                </IconButton>
            </div>
        </div>
    );
}