import React, { useState, useEffect } from 'react';
import { AppBar as MaterialAppBar, Toolbar, Typography, IconButton, Grid, Tooltip, Popover, makeStyles, Button, Avatar } from '@material-ui/core';
import { AccountCircle, Close, ExitToApp } from '@material-ui/icons';
import TaziLogo from '../tazi_assets/tazi_logo';
import { keycloak, logoutKeycloak } from '..';

const useStylesPopover = makeStyles({
    paper: {
        padding: "20px 20px 10px",
    }
});

export interface UserInfo {
    username: string;
    realm?: string;
    email?: string;
    roles?: string[];
    groups?: string[];
    firstName?: string;
    lastName?: string;
    sub?: string;
}

function AppBar() {
    const classes = useStylesPopover();
    const [userInfo, setUserInfo] = useState<UserInfo|null>(null);
    const [profileOpen, setProfileOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement|undefined>();
    useEffect(() => {
        if(keycloak) {
            let info : UserInfo = {
                realm: keycloak.realm,
                username: keycloak.tokenParsed && keycloak.tokenParsed.preferred_username ? keycloak.tokenParsed.preferred_username : "",
                email: keycloak.tokenParsed ? keycloak.tokenParsed.email : undefined,
                roles: keycloak.tokenParsed ? keycloak.tokenParsed.client_roles : undefined,
                groups: keycloak.tokenParsed ? keycloak.tokenParsed.groups : undefined,
                firstName: keycloak.tokenParsed ? keycloak.tokenParsed.given_name : undefined,
                lastName: keycloak.tokenParsed ? keycloak.tokenParsed.family_name : undefined,
                sub: keycloak.tokenParsed ? keycloak.tokenParsed.sub : undefined
            }
            setUserInfo(info);
        }
    }, []);

    const handleCloseProfile = () => {
        setProfileOpen(false);
    }

    const handleClickProfile = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ev.currentTarget);
        setProfileOpen(true);
    }

    return (<div style={{height: "64px"}}>
        <MaterialAppBar position="fixed" color="primary" style={{zIndex: 1101}}>
            <Toolbar>
                <TaziLogo id="header-tazi-logo" color="#FFF" size={60} style={{paddingRight: "10px"}}/>
                <Typography
                    style={{ flexGrow: 1, alignItems: "left", textAlign: "left" }}
                    variant="h6"
                    id="tazi-deploy-header"
                    color="inherit"
                >
                    Tazi License Manager
                </Typography>

                <Tooltip placement="top" title={"Profile"} aria-label="Profile">
                    <IconButton
                        onClick={handleClickProfile}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </MaterialAppBar>

        <Popover
            open={profileOpen}
            classes={classes}
            onClose={handleCloseProfile}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container justify="flex-end" style={{ position: "absolute", top: 0, left: 0, padding: "5px", zIndex: 1}}>
                <IconButton size="small" aria-label="Close" onClick={handleCloseProfile}>
                    <Close fontSize="small" />
                </IconButton>
            </Grid>
            {userInfo&&<Grid container direction="column" alignItems="center">
                <div style={{ margin: "7px" }}>
                    <Avatar>{userInfo.username[0]}</Avatar>
                </div>
                {(userInfo.firstName && userInfo.lastName) &&
                    <Typography variant="subtitle1">{userInfo.firstName + " " + userInfo.lastName}</Typography>
                }
                {userInfo.email &&
                    <Typography variant="body2">{userInfo.email}</Typography>
                }
                <Typography variant="body2">{userInfo.username}</Typography>
                {userInfo.realm &&
                    <Typography style={{ fontWeight: 500 }} variant="body2">{userInfo.realm}</Typography>
                }
            </Grid>}
            <div style={{ width: "100%", marginTop: "10px" }}>
                <Button size="small" style={{ textTransform: "none" }} color="secondary" startIcon={<ExitToApp />} onClick={logoutKeycloak}>
                    Logout
                </Button>
            </div>
        </Popover>
    </div>);
}

export default AppBar;