import axios, { CancelToken } from "axios";

export const authorizationConfig = (
  cancelToken?: CancelToken,
  additionalHeaders?: { [keys: string]: string }
) => ({
  headers: {
    Authorization: "Bearer " + window.sessionStorage.getItem("loginToken"),
    ...additionalHeaders,
  },
  cancelToken,
});

export enum DeploymentMode {
  CLOUD = "Cloud",
  ON_PROMISE = "OnPremise",
}

export interface License {
  id: number;
  ownerId: string;
  licenseKey: string;
  info: LicenseInfo;
  createdDate: number;
  createdUser: string;
  lastModifiedDate: number;
  lastModifiedUser: string;
}

export interface UserReqObj {
  userAccountName: string;
  userAccountID: string;
  deploymentMode: DeploymentMode;
  uniqueKey: string;
}

export interface LicenseInfo extends UserReqObj {
  serverName?: string;
  modellerUserCount: number;
  viewerUserCount: number;
  maxNumberOfCores: number;
  activationExpiredDate: number;
  expiredDate: number;
}

interface ListLicensesResponse {
  licenses: Array<License>;
}

export async function createLicense(license: LicenseInfo): Promise<License> {
  const res = await axios.post(
    `${
      process.env.REACT_APP_HOST && process.env.REACT_APP_PORT
        ? process.env.REACT_APP_HOST + ":" + process.env.REACT_APP_PORT
        : process.env.REACT_APP_HOST
        ? process.env.REACT_APP_HOST
        : ""
    }/licenses`,
    license,
    authorizationConfig()
  );
  return res.data as License;
}

export async function updateLicense(
  licenseId: number,
  contents: LicenseInfo
): Promise<License> {
  const result = await axios.put(
    `${
      process.env.REACT_APP_HOST && process.env.REACT_APP_PORT
        ? process.env.REACT_APP_HOST + ":" + process.env.REACT_APP_PORT
        : process.env.REACT_APP_HOST
        ? process.env.REACT_APP_HOST
        : ""
    }/licenses/${licenseId}`,
    contents,
    authorizationConfig()
  );
  return result.data as License;
}

export async function deleteLicense(id: number) {
  await axios.delete(
    `${
      process.env.REACT_APP_HOST && process.env.REACT_APP_PORT
        ? process.env.REACT_APP_HOST + ":" + process.env.REACT_APP_PORT
        : process.env.REACT_APP_HOST
        ? process.env.REACT_APP_HOST
        : ""
    }/licenses/${id}`,
    authorizationConfig()
  );
}

export async function listLicenses(): Promise<ListLicensesResponse> {
  const res = await axios.get(
    `${
      process.env.REACT_APP_HOST && process.env.REACT_APP_PORT
        ? process.env.REACT_APP_HOST + ":" + process.env.REACT_APP_PORT
        : process.env.REACT_APP_HOST
        ? process.env.REACT_APP_HOST
        : ""
    }/licenses`,
    authorizationConfig()
  );
  return {
    licenses: res.data,
  };
}

export async function getLicenseRequirements(key: string): Promise<UserReqObj> {
  const res = await axios.post(
    `${
      process.env.REACT_APP_HOST && process.env.REACT_APP_PORT
        ? process.env.REACT_APP_HOST + ":" + process.env.REACT_APP_PORT
        : process.env.REACT_APP_HOST
        ? process.env.REACT_APP_HOST
        : ""
    }/licenses/requirements`,
    key,
    authorizationConfig(undefined, { "Content-Type": "text/plain" })
  );
  return res.data as UserReqObj;
}
