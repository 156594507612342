import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, DialogProps, Grid, Switch, FormControl, FormControlLabel, Select, MenuItem, Divider } from '@material-ui/core';
import { DeploymentMode, getLicenseRequirements, License, LicenseInfo, UserReqObj } from '../Services/ApiServices';
import { convertTStoDateTimeValue } from '../Services/Util';
import { useSnackbar } from 'notistack';

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

interface LicenseDialogProps extends DialogProps {
    license?: License;
    onSend: (info: LicenseInfo, id?: number) => void;
    onCancel: () => void;
}

export default function LicenseDialog(props: LicenseDialogProps) {
    const [userAccountName, setUserAccountName] = useState<string>("");
    const [userAccountId, setUserAccountId] = useState<string>("");
    const [serverName, setServerName] = useState<string>("");
    const [modelCount, setModelCount] = useState<number>(3);
    const [viewCount, setViewCount] = useState<number>(7);
    const [maxCoreCount, setMaxCoreCount] = useState<number>(-1);
    const [deploymentMode, setDeploymentMode] = useState<string>(DeploymentMode.ON_PROMISE);
    const [expiredActivation, setExpiredActivation] = useState<string>(convertTStoDateTimeValue(Date.now() + (5*(24*60*60*1000)))); // +5 days
    const [expired, setExpired] = useState<string>(convertTStoDateTimeValue(Date.now()));
    const [uniqueId, setUniqueId] = useState<string>("");
    const [userRequirementsKey, setUserRequirementsKey] = useState<string>("");
    const [requirementsKeyError, setRequirementsKeyError] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(props.license){
            setUserAccountName(props.license.info.userAccountName);
            setUserAccountId(props.license.info.userAccountID);
            setServerName(props.license.info.serverName || "");
            setModelCount(props.license.info.modellerUserCount);
            setViewCount(props.license.info.viewerUserCount);
            setMaxCoreCount(props.license.info.maxNumberOfCores);
            setDeploymentMode(props.license.info.deploymentMode);
            // activation expired date needs to be updated anyway, so we do not load old date here
            // setExpiredActivation(convertTStoDateTimeValue(props.license.info.activationExpiredDate));
            setExpired(convertTStoDateTimeValue(props.license.info.expiredDate));
            setUniqueId(props.license.info.uniqueKey);
        }
    }, []);

    const checkSubmitValidation = () => {
        return Boolean(userAccountName) && Boolean(userAccountId) && modelCount>=1 && viewCount>=1 && Boolean(deploymentMode) && Boolean(expired) && Boolean(expiredActivation) && Boolean(uniqueId);
    }

    const handleSubmit = () => {
        props.onSend({
            userAccountName: userAccountName,
            userAccountID: userAccountId,
            serverName: serverName,
            modellerUserCount: modelCount,
            viewerUserCount: viewCount,
            maxNumberOfCores: maxCoreCount,
            deploymentMode: deploymentMode as DeploymentMode,
            expiredDate: Date.parse(expired),
            activationExpiredDate: Date.parse(expiredActivation),
            uniqueKey: uniqueId
        }, props.license?props.license.id:undefined);
    };

    const handleCancel = () => {
        props.onCancel();
    };

    const handleAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserRequirementsKey("");
        setUserAccountName(event.target.value);
    }

    const handleAccountIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserAccountId(event.target.value);
        setUserRequirementsKey("");
    }

    const handleModellerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModelCount(parseInt(event.target.value));
    }

    const handleViewerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setViewCount(parseInt(event.target.value));
    }

    const handleMaxCoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxCoreCount(parseInt(event.target.value));
    }

    const handleExpiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpired(event.target.value);
    }

    const handleActivationExpiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpiredActivation(event.target.value);
    }

    const handleUniqueIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUniqueId(event.target.value);
        setUserRequirementsKey("");
    }

    const handleUserRequirementsKeyChange = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const value = event.clipboardData.getData("text/plain");
        setUserRequirementsKey(value);
        getLicenseRequirements(value).then(res=>{
            try {
                if(!(res.userAccountID && res.deploymentMode && res.uniqueKey && res.userAccountName)){
                    throw new Error("Object not contains all requirement fields");
                }
                setUserAccountName(res.userAccountName);
                setUserAccountId(res.userAccountID);
                setDeploymentMode(res.deploymentMode)
                setUniqueId(res.uniqueKey);
                setRequirementsKeyError(false);
            } catch (err) {
                console.log(err);
                enqueueSnackbar(err.message, { variant: "error" });
                setRequirementsKeyError(true);
            }
        }).catch(err =>{
            console.log(err);
            enqueueSnackbar(err.message, { variant: "error" });
            setRequirementsKeyError(true);
        })
    }

    const handleServerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServerName(event.target.value);
    }

    return (
        <Dialog {...props} disableBackdropClick onKeyPress={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit();
            }
        }}>
            <DialogTitle id="license-dialog-title">{props.license?"Update License":"Create License"}</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <Grid container style={!(props.license)?{border: "1px solid #ddd", borderRadius: "10px", padding: "10px"}:{}}>
                    {!(props.license)&&[<Grid item xs={12} style={{padding: "0 5px"}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="userReqKey"
                            label="User Requirements Key"
                            fullWidth
                            value={userRequirementsKey}
                            error={requirementsKeyError}
                            helperText={requirementsKeyError?"Key is not valid!":"Only paste area"}
                            // onChange={handleUserRequirementsKeyChange}
                            onPaste={handleUserRequirementsKeyChange}
                        />
                    </Grid>,
                    <Grid container justify="space-evenly" wrap="nowrap" alignItems="center" style={{margin:"10px 0"}}>
                        <Grid item xs={5}><Divider/></Grid>
                        OR
                        <Grid item xs={5}><Divider/></Grid>
                    </Grid>]}
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            autoFocus={Boolean(props.license)}
                            margin="dense"
                            id="userAccountName"
                            label="Account Name"
                            fullWidth
                            value={userAccountName}
                            onChange={handleAccountNameChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="userAccountId"
                            label="Account Id"
                            fullWidth
                            value={userAccountId}
                            onChange={handleAccountIdChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="uniqueId"
                            label="Unique Id"
                            fullWidth
                            value={uniqueId}
                            onChange={handleUniqueIdChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <Grid container direction="column" justify="flex-end" style={{height: "100%"}}>
                            <Select
                                style={{ width: "100%", margin: "8px 0 4px"}}
                                value={deploymentMode}
                            >
                                {enumKeys(DeploymentMode).map((type)=>{
                                    return <MenuItem onClick={()=>{setDeploymentMode(DeploymentMode[type]); setUserRequirementsKey("");}} value={DeploymentMode[type]}>{DeploymentMode[type]}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{padding: !(props.license)?"10px":"0"}}>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="serverName"
                            label="Server Name"
                            fullWidth
                            value={serverName}
                            onChange={handleServerNameChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="maxCoreCount"
                            label="Max Number of Cores"
                            type="number"
                            fullWidth
                            value={maxCoreCount}
                            onChange={handleMaxCoreChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="modeller"
                            label="Modeller Count"
                            type="number"
                            fullWidth
                            value={modelCount}
                            onChange={handleModellerChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            margin="dense"
                            id="viewer"
                            label="Viewer Count"
                            type="number"
                            fullWidth
                            value={viewCount}
                            onChange={handleViewerChange}
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            id="activation-expired-datetime"
                            label="Activation Expired Date"
                            type="datetime-local"
                            value={expiredActivation}
                            onChange={handleActivationExpiredChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} style={{padding: "0 5px"}}>
                        <TextField
                            id="expired-datetime"
                            label="Expired Date"
                            type="datetime-local"
                            value={expired}
                            onChange={handleExpiredChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!checkSubmitValidation()} color="secondary">
                    {props.license?"Update":"Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}