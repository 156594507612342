import React from 'react';
import AppBar from "./AppBar/AppBar";
import LicenseList from "./Licenses/LicenseList";
import { MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AppTheme from "./theme";
import './App.css';

function App() {
  return (
    <MuiThemeProvider theme={AppTheme}>
        <SnackbarProvider maxSnack={3}>
          <div className="App">
            <AppBar/>
            <LicenseList />
          </div>
        </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
